@import '~bootstrap/scss/bootstrap';
@import 'custom';

//Yleiset tyylit

html,body {
  background-color: white; 
  height: 100vh;
  width: 100vw;
  margin: 0;
  font-family: $fonts; 
  font-weight: 400;
  font-style: normal;
  color: $font-color;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h3 {
  font-family: "swear-display", serif;
  font-weight: 500;
  font-style: italic;
  color: $font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $font-color;
  text-decoration: underline;
}

a:hover {
  color: #2E3D33;
}

a:active {
  color: #2E3D33;
}

a:visited {
  color: $font-color;
}

//Forest-tyylit

.layout-f-container {
  padding: 20px;
}
.header-f-container {
    margin-bottom: 2vh;
}

//Ocean tyylit


.header-o-container {
  background-image: linear-gradient( to right, transparent, #F7EDDB, transparent); 
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #E5D1B5;
  border-top: 1px solid #F7EDDB;
  margin-bottom: 2vh;
  color: #2E3D33;
}

.o-headertext {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 35px;
  font-size: 2.5em;
  font-family: $headerfont;
  font-weight: 500;
  font-style: italic;
  color: $font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.o-linktext {
  font-size: 1.5em;
  font-family: $headerfont;
  font-weight: 500;
  font-style: italic;
  color: $font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.o-headerbutton {
  --border: 1px;    /* the border width */
  --slant: 0.7em;   /* control the slanted corners */
  --color: #2E3D33; /* the color */
  
  font-size: 20px;
  padding: 0.4em 1.2em;
  border: none;
  cursor: pointer;
  font-weight: normal;
  color: var(--color);
  background: 
     linear-gradient(to bottom left,var(--color)  50%,#0000 50.1%) top right,
     linear-gradient(to top   right,var(--color)  50%,#0000 50.1%) bottom left;
  background-size: calc(var(--slant) + 1.3*var(--border)) calc(var(--slant) + 1.3*var(--border));
  background-repeat: no-repeat;
  box-shadow:
    0 0 0 200px inset var(--s,#0000),
    0 0 0 var(--border) inset var(--color);
  clip-path: 
      polygon(0 0, calc(100% - var(--slant)) 0, 100% var(--slant),
              100% 100%, var(--slant) 100%,0 calc(100% - var(--slant))
             );
  transition: color var(--t,0.3s), background-size 0.3s;
}
.o-headerbutton:focus-visible {
  outline-offset: calc(-1*var(--border));
  outline: var(--border) solid #E5D1B5;
  clip-path: none;
  background-size: 0 0;
}
.o-headerbutton:hover,
.o-headerbutton:active{
  background-size: 100% 100%;
  background-color: #F7EDDB;
  color: #fff;
  --t: 0.2s 0.1s;
}
.o-headerbutton:active {
  --s: #0005;
  transition: none;
}

/* .o-headerbutton {
  background-color: white !important;
  color: black !important;
  border: 2px solid #555555 !important;
  padding: 16px 32px !important;
  text-align: center !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 16px !important;
  margin: 4px 2px !important;
  transition-duration: 0.4s !important;
  cursor: pointer !important;
}


.o-headerbutton:hover {
  background-color: #555555 !important;
  color: white !important;
}
 */

.o-footer-container { 
  justify-content: center;
  align-items: center;
  border-top: 1px solid #E5D1B5;
  margin-top: 2vh;
}

.o-footertext {
font-family: "swear-display", serif;
font-weight: 500;
font-style: italic;
color: $font-color;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
padding: 20px;
align-self: flex-end;
}


//Mountain tyylit

.layout-m-container {
  padding: 0%;
  justify-content: center;
  align-items: center;
}

.m-footer-container { 
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 2vh;
}

.header-m-container {
  background-image: linear-gradient( to right, transparent, #F7EDDB, transparent);
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 2vh;
  color: #2E3D33;
}

.m-headertext {
  color: white;
  padding: 1.5em;
  font-family: $fonts;
  color: $font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.m-navdropdowntext {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1em;
  font-size: 1em;
  font-family: $fonts;
  color: $font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

m-navigator-container {
  padding: 20px;
  font-family: $fonts;
  justify-content: center;
  align-items: center;
}

.m-button {
  background-color: white;
  color: #2E3D33;
  border: 1px solid #2E3D33;
  border-radius: 0%;
  padding: 10px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.m-button:hover {
  background-color: #2E3D33;
  color: white;
}

//Home-tyylit

.home1-button {
  background-color: #ffffff;
  color: #2E3D33;
  border: 1px solid #2E3D33;
  border-radius: 0%;
  padding: 10px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.home1-button:hover {
  background-color: #2E3D33;
  color: white;
}

.home-button {
  background-color: #F7EDDB;
  color: #2E3D33;
  border: 1px solid #2E3D33;
  --slant: 0,7em
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.home-button:hover {
  background-color: #2E3D33;
  color: white;
}

.blaketon {
  font-family: mrblaketon-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: $font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    }

.footer-container {
    margin-top: 2vh;
}


.footertext {
    background-color: tomato;
    color: white;
    padding: 20px;
    align-self: flex-end;
}

.home-container {
    background-color: lightblue;
    padding: 20px;
}

//Login-tyylit

.login-container {
    padding: 20px;
}


//Some-tyylit

.social-container {
    background: #F7EDDB;
    padding: 25px 50px;
  }

.social1-container {
    background: rgba(0, 0, 0, 0.1);
    padding: 25px 50px;
  }
  
  a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
  }
  
  a.social:hover {
    transform: translateY(-2px);
  }

  a.youtube {
    color: #eb3223;
  }
  
  a.facebook {
    color: #4968ad;
  }
  
  a.twitter {
    color: #49a1eb;
  }
  
  a.instagram {
    color: black;
  }


